import React, { useRef, useEffect, useState, useLayoutEffect } from "react"
import {css} from '@emotion/core'
import Img from "gatsby-image"
import phoneOutlineSVG from "../icons/call-outline-ionic.svg"
import {SVG__EvaIcons__menu_outline} from "../Utility/svg-loader.js"

/**
 * A React component navigation bar
 * @param {String} dataKey - a copy of key used for further naming conventions
 * @param {childImageSharp.fluid} logoImg - A gatsby node to display in the top-left as a logo
 * @param {String[]} menuJumpIDs - array of strings representing the element id's to jump to upon a nav button press. They have the hashtag in them.
 * @param {String[]} menuTitles - array of strings for the visual button titles for sections to navigate to
 * @param {Number} menuIndex - This index is used to highlight the nav button that represents the section currently visible for the current scroll location on the page
 * 
 */

function NavComponent(props)
{
  const [state, setState] = useState( () => {
    return {
      menuIndex: props.menuIndex, 
      //? this doesn't work in production build. Cant have window in render function
      // hamburgerOn: (window.matchMedia('(min-width: 800px)').matches ? false : true),
      hamburgerOn: true,
      hamburgerDropdownActive: false 
    }
  })

  const navBar = useRef(null)

  function handleCommand(commandedState) {
    let innerStateEntries = Object.entries(state)
    let commandedStateEntries = Object.entries(commandedState)
    let updatedValues = {}

    for (let [commandKey, commandValue] of commandedStateEntries) {
      let foundInnerStateEntry = innerStateEntries.find(innerStateEntry =>{
        return innerStateEntry[0] === commandKey
      })

      if (foundInnerStateEntry !== undefined){
        const innerStateValue = foundInnerStateEntry[1]
        if (innerStateValue === commandValue) console.log(props.dataKey + " command of '" + commandKey + "' has value: " + commandValue + " that already matched inner state")

        else updatedValues[commandKey] = commandValue
      }
      else {
        console.log(props.dataKey + " command of '" + commandKey + "' not recognized")
      }
    }

    if (Object.keys(updatedValues).length > 0) setState(prevState => {return{...prevState, ...updatedValues}}) 
  }

  //Ceilings are used in some scroll stuff (scrollY may be the cullprit) because the actual scroll position ends up being a weird decimal within 1 unit below of what it ought to be
  function handleScroll() {
    //fringe case scroll to bottom and last section doesn't take up full page height. This will ensure last section is highlighted
    if (Math.ceil(window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
      const lastIndex = props.menuJumpIDs.length - 1
      if (state.menuIndex !== lastIndex) handleCommand({ menuIndex: lastIndex })
      return
    }

    for (let i = props.menuJumpIDs.length - 1; i >= 0; i--) {
      let possibleIndex = i
      let scrollSection = document.querySelector(props.menuJumpIDs[possibleIndex])

      //If window scroll >= section position compensate for fixed navbar
      if (Math.ceil(window.scrollY) >= scrollSection.offsetTop - navBar.current.offsetHeight) {
        if (possibleIndex !== state.menuIndex) {
          handleCommand({menuIndex: possibleIndex })
        }
        break
      }
    }
  }

  //Mobile is initially assumed. So after the very first render, check to see if we're in desktop mode and update if it turns out we are
  useLayoutEffect(()=>{
    if (window.matchMedia("(min-width: 800px)").matches){
      handleCommand({hamburgerOn: false})
    }
  },[])

  //Media matching for switching between hamburger menu and full menu in navbar
  useEffect(()=>{
    //? This function must be arrowStyle. If regular function style, you'll get stale state values and tons of matches while mid-drag causing tons of rerender attempts visibly slowing things.
    const handleHamburgerMode = (match) => {
      if (match.matches){ //Just matched the media
        handleCommand({hamburgerOn: false})
      } else { //Just lost the match
        handleCommand({hamburgerOn: true})
      }
    }
    const widthMatcher = window.matchMedia("(min-width: 800px)")
    widthMatcher.addListener(handleHamburgerMode) 

    return( ()=>{
      widthMatcher.removeListener(handleHamburgerMode)
    })
  }, [state.hamburgerOn])

  //register new scroll listeners and remove old ones each render so that their handleScroll function keeps access to the latest menuIndex from state. 
  useEffect(() => {

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [state.menuIndex])

  //Allow closing menu by tapping elsewhere
  useEffect(()=>{
    function handleOutsidePress(e){
      if (e.target.id === "hamburger") return
      if (state.hamburgerDropdownActive) handleCommand({hamburgerDropdownActive: false})
    }
    document.addEventListener('click', handleOutsidePress)

    return( ()=>document.removeEventListener('click', handleOutsidePress))
  },[state.hamburgerDropdownActive])


  const handleLinkPress = (anchorID, e) => {
    let anchorElement = document.querySelector(anchorID)
    window.scrollTo(0, anchorElement.offsetTop - navBar.current.offsetHeight)
  }

  //Prepare the menu buttons with special underline for button corresponding to scroll section
  let menuLinkArray = []
  const tabbable = (!state.hamburgerOn || state.hamburgerDropdownActive) ? 0 : -1

  for (let i = 0; i < props.menuTitles.length; i++) {
    let underlineFocuser = <hr className="underliner" />
    if (i === state.menuIndex)
      underlineFocuser = React.cloneElement(underlineFocuser, {
        id: "focusedUnderline",
      })

    let menuLink = (
      <button className="navMenuButton" tabIndex={tabbable} onClick={e => handleLinkPress(props.menuJumpIDs[i], e)}>
        {props.menuTitles[i]}
        {underlineFocuser}
      </button>
    )

    menuLink = <li key={'menuButton' + props.menuTitles[i]}>{menuLink}</li>
    menuLinkArray.push(menuLink)
  }

  return (
    <nav role='navigation' id={props.dataKey} css={getCSS()} ref={navBar} className='LandmarkSection'>
      <div key='navMenuLogoContact' id="leftSide">
        <Img alt='navigation logo' fadeIn={false} className={"logoImg fromNoToShow"} loading='eager' style={{height: '100%'}} imgStyle={{ objectFit: "contain", objectPosition: "left center"}} fluid={props.logoImg}></Img>

        {/*//? responsively set to text or icon in css */}
        <a id="contactNumber" href='tel:954-282-1077' aria-label='contact: 954 282 1077'/>
      </div>

      {state.hamburgerOn && 
      <button key='hamburgerButton' id="hamburger" aria-label='navigation' 
      aria-expanded={state.hamburgerDropdownActive}
      onClick={()=>handleCommand({hamburgerDropdownActive: !state.hamburgerDropdownActive})}>
        <SVG__EvaIcons__menu_outline />
      </button>}

      <div key='navButtonContainer' className={state.hamburgerOn === true ? (state.hamburgerDropdownActive ? "expandedHamburger dropdownVisible" : "expandedHamburger") : "desktopNavbar"} >
        <ul className="navLinkList" aria-label='navigation'>{menuLinkArray}</ul>
      </div>
    </nav>
  )
}

export default React.memo(NavComponent, ()=>true)

//!
//*
//*CSS FROM THIS POINT BELOW
//*
//*CSS FROM THIS POINT BELOW
//*
//?

const wideNavHeight = '4rem'
const narrowNavHeight = '3.25rem'

const getCSS = () => css`

  display: flex;
  position: fixed;
  top: 0px;
  z-index: 10;
  background-color: rgba(26, 26, 26, 0.8);
  background-image: linear-gradient(to right, rgba(26, 26, 26, 1) 50%, rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.7));
  height: ${narrowNavHeight};
  width: 100%;
  justify-content: space-between;

  #leftSide {
    display: flex;
    flex-shrink: 3; //? for oversized words, this allows the nav menu items to take up more space
    min-width: 0;
  }

  .logoImg {
    min-width: 0px;
    width: calc(${narrowNavHeight} * 2.8);
  }

  #contactNumber {
    display: inline-block;
    position: relative;
    font-family: 'maven pro';
    font-size: 1.25em;
    color: white;
    align-self: center;
    min-width: 0;
    height: 100%;
    width: ${narrowNavHeight};

    background:url(${phoneOutlineSVG});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
  }

  .desktopNavbar {
    display: block;
    padding-right: 20px;
    min-width: 0; //? prevent overflow
    flex-shrink: 0;
  }

  #hamburger {
    display: flex;
    height: 100%;
    width: ${narrowNavHeight};
    background-color: transparent;
    align-items: center;
    justify-content: center;
    fill: white;
    border: none;
    padding: 0px;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: white solid thin;
      outline-offset: -1px;
    }

    /* needed when flexbox is used because the svg itself must have a dimension */
    svg {
      height: 65%;
    }

    /*don't let the svg get the pointer events like click */
    * { pointer-events: none; }
  }

  //?This is the actual dropdown list in hamburger mode
  .expandedHamburger {
    display: block;
    position: absolute;
    padding: 10px 0px;
    top: 100%;
    right: -100%;
    background-color: black;
    background-color: rgba(26, 26, 26, 0.9);
    transition: right 1s ease-in;
  }

  .dropdownVisible {
    /*!important is needed since we want this added class to override the id #expandedHamburger*/
    right: 0% !important;
    transition: right 0.5s !important;
  } 

  .navLinkList{
    display: flex;
    flex-direction: column;
    font-size: 1.25em;
    justify-content: flex-end;
    align-items: center;
    min-width: 0;
    max-width: 700px;
    //. important to use min height. Reg height was causing big propblems in ipad because it got confused on orientation switches and would try to keep the height of the navbar even when switching back to expanded hamburger mode.
    min-height: 100%; 
    margin: 0px 10px;
    padding: 0px;
  }

  li {
    display: flex;
    align-items: center;
    min-width: 0; //? important to prevent buttons from overflowing flex container
  }
  
  .navMenuButton {
    min-width: 0;
    width: 100%;

    font-size: 1.25em;
    display: inline-block;
    /* word-break: break-word; */
    position: relative;
    color: white;
    background-color: transparent;
    border: none;
    padding: 2px 10px;
    margin: 3px 0px; //? this is important so buttons don't get squished vertically in phone mode

    &:focus {
      outline: white solid thin;
      outline-offset: -4px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .underliner {
    position: absolute;
    top: 105%;
    left: auto;
    right: 10%;
    height: 2px;
    width: 0%;
    margin: 0;
    background: white;
    border: none;
    transition: width 0.3s ease-in-out, right, left;
  }

  #focusedUnderline{
    width: 80%;
    left: 10%;
    right: auto;
    transition: width 0.3s ease-in-out, right, left;
  }

  .fromNoToShow {
    animation: fadeIn 1.5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50%{
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  //? Tablet portrait, make nav bar taller. min-height to account for possible landscape phone mode
  @media (min-width: 600px) and (min-height: 400px){
    height: ${wideNavHeight};

    .logoImg {
      width: calc(${wideNavHeight} * 2.8)
    }

    #contactNumber {
      background: none;
      width: auto;

      &::before {
        display: block;
        position: relative;
        content: '954-282-1077';
        text-align: center;
        height: 100%;
        align-self: center;
        display: flex;
        align-items: center;
        flex-shrink: 1;
      }
    }

    .navLinkList{
      font-size: 1.5em;
    }

    #hamburger {
      width: ${wideNavHeight};
    }
  }

  //? Desktop or tablet landscape mode, visible nav buttons instead of hamburger with dropdown 
  @media (min-width: 800px) {
    .navLinkList {
      flex-direction: row;
    }
  }
/* } */
`

