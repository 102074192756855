import React from "react"

//This file has functions that return svg's as react components so they can be used easily. The functions start off SVG__ and then attempt to have the exact same naming convention as the source svg file which was copied within the return statement. This is for the sake of easily knowing where the svg code came from. 

//Best practice: don't name svg files with dashes (-) otherwise this function naming convention can't work. Stick to underscores when possible. If original author used dashes then I would replace with underscores here, but not in my source file.

export const SVG__jhEvaIcons__Padless__arrow_ios_forward_outline = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 20">
    <path
      d="M 1.462773,19.998506 A 1.4332778,1.4253316 0 0 1 0.54083282,19.683824 1.4332778,1.4253316 0 0 1 0.32548283,17.676977 L 6.6354955,9.9462198 0.33376288,2.3814708 A 1.4332778,1.4253316 0 0 1 0.51968768,0.36888816 1.4332778,1.4253316 0 0 1 2.615147,0.55275891 L 9.6607537,9.0049273 a 1.4332778,1.4253316 0 0 1 0.026157,1.8099837 l -7.042058,8.653528 a 1.4332778,1.4253316 0 0 1 -1.182083,0.530067 z" />
  </svg>

export const SVG__jhEvaIcons__Padless__arrow_ios_back_outline = () => 
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 20">
    <path
      d="M 8.4241809,19.999903 A 1.4431175,1.428437 0 0 1 7.2985499,19.471381 L 0.32829172,10.900759 a 1.4431175,1.428437 0 0 1 0,-1.8141155 L 7.5438799,0.51602152 a 1.4449203,1.4302214 0 0 1 2.222401,1.82839928 l -6.450736,7.6564222 6.234268,7.656423 a 1.4431175,1.428437 0 0 1 -1.125632,2.342637 z" />
  </svg>


export const SVG__EvaIcons__close_outline = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="close"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></g></g></svg>

export const SVG__EvaIcons__menu_outline = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="menu"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/><rect x="3" y="11" width="18" height="2" rx=".95" ry=".95"/><rect x="3" y="16" width="18" height="2" rx=".95" ry=".95"/><rect x="3" y="6" width="18" height="2" rx=".95" ry=".95"/></g></g></svg>

export const SVG__EvaIcons__checkmark_outline = () =>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g data-name="Layer 2"><g data-name="checkmark"><rect width="24" height="24" opacity="0"/><path d="M9.86 18a1 1 0 0 1-.73-.32l-4.86-5.17a1 1 0 1 1 1.46-1.37l4.12 4.39 8.41-9.2a1 1 0 1 1 1.48 1.34l-9.14 10a1 1 0 0 1-.73.33z"/></g></g></svg>

export const SVG__Ionic__call_outline = () =>
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512"><title>ionicons-v5-g</title><path d="M451,374c-15.88-16-54.34-39.35-73-48.76C353.7,313,351.7,312,332.6,326.19c-12.74,9.47-21.21,17.93-36.12,14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48,5.41-23.23,14.79-36c13.22-18,12.22-21,.92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9,44,119.9,47,108.83,51.6A160.15,160.15,0,0,0,83,65.37C67,76,58.12,84.83,51.91,98.1s-9,44.38,23.07,102.64,54.57,88.05,101.14,134.49S258.5,406.64,310.85,436c64.76,36.27,89.6,29.2,102.91,23s22.18-15,32.83-31a159.09,159.09,0,0,0,13.8-25.8C465,391.17,468,391.17,451,374Z" style={{fill: 'none', stroke: 'black', strokeMiterlimit: 10, strokeWidth: '32px'}} /></svg>

export const SVG__Ionic__mail_outline = () =>
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512"><title>ionicons-v5-o</title><rect x={48} y={96} width={416} height={320} rx={40} ry={40} style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><polyline points="112 160 256 272 400 160" style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /></svg>

export const SVG__Ionic_location_outline = () =>
  <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512"><title>ionicons-v5-n</title><path d="M256,48c-79.5,0-144,61.39-144,137,0,87,96,224.87,131.25,272.49a15.77,15.77,0,0,0,25.5,0C304,409.89,400,272.07,400,185,400,109.39,335.5,48,256,48Z" style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><circle cx={256} cy={192} r={48} style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /></svg>