import React from "react"
import {css} from '@emotion/core'
import testJpg from '../background/background-1.jpg'
import Img from "gatsby-image"

/**
 * @param {String} dataKey - a copy of key used for further naming conventions
 * @param {Function} dispatch - dispatch to Index for the mounting of the modal virtual tour
 * @param {Gatsby Fluid} background - meant to be a static background image
 */
export default React.memo(props => {

function handleTourClick(e){
  props.dispatch({active: true, originFocusId:e.target.id})
}

return(
  <section id={props.dataKey} css={getCSS()} aria-labelledby={'header' + props.dataKey} className='LandmarkSection'>
    <Img 
      alt="" 
      style={{width: "100%", height: "100%", position: "absolute", top: "0", left: "0" }} 
      objectFit="cover"
      fluid={props.background}>
    </Img>
    <div  style={{width: "100%", height: "100%", position: "absolute", top: "0", left: "0", backgroundColor: "rgba(0,0,0,0.3)"}} />

    <h2 className="section-title" id={'header' + props.dataKey}>Services We Offer</h2>

    <div id="services-container">
      <div id="left-subsection" className="service-subsection">
        <h3>Drone Photography/Video</h3>
        <p>Nothing speaks like the beauty of aerial pictures. Take advantage of it for your business or property and give that enhanced perspective. A bird's eye view need not be out of reach.</p>
      </div>

      <div id="middle-subsection" className="service-subsection">
        <h3>Business/Residential Photography</h3>
        <p>Work with me on your terms to find that perfect photo for your business or home. I have worked in corporate offices, restaurants, and specialty facilities such as gyms, salons, and even factories. The true beauty of your business or home is unleashed through the lense of Monckton Photography.</p>
      </div>

      <div id="right-subsection" className="service-subsection">
        <h3>Virtual Tours</h3>
        <p>You can now give a personal and interactive viewing of your location, and all from the comfort of one's own computer or even phone. This is thanks to virtual tour technology. See the below sample of my work for Domino's and decide if it's right for you.</p>
      </div>
    </div>

    <button id='virtual-tour-button' onClick={handleTourClick}>Virtual Tour Demo</button>

  </section>
)
},()=>true)


// export const ServicesComponentEmotion = (sectionId) => css`
const getCSS = () => css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 600px;
  padding: 50px 0px;
  background: transparent;

//?for IE support. Gotta use higher z-indexes for child content to compensate for this
  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.35);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .section-title {
    margin-bottom: 50px;
    color: white;
    font-size: 2rem;
    z-index: 1;
    text-shadow: 0px 0px 2px black;
  }

  #services-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
    padding: 0px 20px;
    justify-content: center;
    z-index: 1;
  }

  .service-subsection{
    background-color: white;
    margin: 20px 0px;
    text-align: center;
    padding: 0px 20px;
    flex: 1;
    box-shadow: 0px 0px 20px -5px black;
    h3 {
      font-size: 1.5rem
    }
    p {
      font-size: 1.25rem;
    }
  }

  #left-subsection {
    box-shadow: -10px 0px 20px -5px black;
    
  }

  #right-subsection {
    box-shadow: 10px 0px 20px -5px black;
  }

  #middle-subsection {
    box-shadow: 0px 0px 30px -5px black;
    z-index: 1;
  }

  #virtual-tour-button {
    margin-top: 30px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: black;
    color: white;
    letter-spacing: 2px;
    z-index: 1;
    padding: 20px;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: white solid thin;
      outline-offset: -5px;
    }
  }

  @media (min-width: 750px) {
    //?These first 2 stylings are merely for taking up more v-space if not much writing
    padding-bottom: 100px;
    .section-title {
      margin-bottom: 100px;
      text-shadow: 0px 0px 6px black;
    }

    #services-container{
      flex-direction: row;
      width: 1200px;
      max-width: 100%;
    }

    #middle-subsection {
      margin: 0;
    }

    #virtual-tour-button {
      margin-top: 75px;
      font-size: 1.15rem;
    }
  }
`
