import React, { useState, useRef, useEffect } from "react"
import Img from "gatsby-image"
import {css} from '@emotion/core'
import {SVG__jhEvaIcons__Padless__arrow_ios_forward_outline, SVG__jhEvaIcons__Padless__arrow_ios_back_outline, SVG__EvaIcons__close_outline} from "../Utility/svg-loader.js"

/**
 * A React component for displaying a carousel style image viewer
 * 
 * @param {String} dataKey - a copy of key used for further naming conventions
 * @param {Number} sourceImgIndex - the index in the sourceImageArray where the currently shown image is found
 * @param {Number[]} currentImgArray - An array of all the sourceImgIndexes that can be carouseled through in this modal
 * @param {graphqlImgNodes[]} sourceImageArray - gatsby nodes all images
 * @param {Function} callbackStateUpdate - a callback function for outside communication
 * @param {Ref} commandRef - an optional forwarded Ref for tapping into the handleCommand function if you want to update state from outside
 * @param {Function} dispatch - dispatch to Index for the unmounting of the modal carousel
 * 
 */

function ModalCarouselComponent(props) 
{
  const [state, setState] = useState({
    sourceImgIndex: props.sourceImgIndex, 
    currentImgArray: props.currentImgArray
  })

  const dragHandles = [useRef(null), useRef(null), useRef(null)]
  const lastMousePos = useRef(null)
  const originalMousePos = useRef(null)
  const touchStartTime = useRef(null)

  //This function is a "smart" state updater. Pass it an object with all the key value pairs of the state that you want updated. It will check to make sure keys exist and values aren't identical and update accordingly.
  function handleCommand(commandedState) {
    let innerStateEntries = Object.entries(state)
    let commandedStateEntries = Object.entries(commandedState)
    let updatedValues = {}

    for (let [commandKey, commandValue] of commandedStateEntries) {
      let foundInnerStateEntry = innerStateEntries.find(innerStateEntry =>{
        return innerStateEntry[0] === commandKey
      })

      if (foundInnerStateEntry !== undefined){
        const innerStateValue = foundInnerStateEntry[1]
        if (innerStateValue === commandValue) console.log(props.dataKey + " command of '" + commandKey + "' has value: " + commandValue + " that already matched inner state")

        else updatedValues[commandKey] = commandValue
      }
      else {
        console.log(props.dataKey + " command of '" + commandKey + "' not recognized")
      }
    }

    if (Object.keys(updatedValues).length > 0){
      setState(prevState => {return{...prevState, ...updatedValues}})
    }  
  }

  //initialize forwarded commandRef if one was initially provided
  if (props.commandRef !== undefined && props.commandRef.current === null) props.commandRef.current = handleCommand

  //? make all other sections aria hidden when modal is active, and restore when inactive
  useEffect(()=>{
    const allSections = [...document.querySelectorAll('.LandmarkSection')]

    allSections.forEach(section => {
      if (section.id !== 'ModalComponent'){
        section.setAttribute('aria-hidden', 'true')
      }
    })

    function tabHandler(e) {
      let KEY_TAB = 9
      let KEY_ESC = 27
      const firstFocusable = document.querySelector('.modalChooserButton.left-button')
      const lastFocusable = document.querySelector('#exit-button')
      if(e.keyCode === KEY_TAB){
        if (e.shiftKey){
          if (document.activeElement === firstFocusable){
            e.preventDefault()
            lastFocusable.focus()
          }
        }
        else if (document.activeElement === lastFocusable) {
          e.preventDefault()
          firstFocusable.focus()
        }
      }
      else if (e.keyCode === KEY_ESC) {
        handleExitClick(null)
      }
    }

    document.querySelector('.modalChooserButton.left-button').focus()
    document.addEventListener('keydown', tabHandler)
    
    return ()=> {
      document.removeEventListener('keydown', tabHandler) 
      allSections.forEach(section =>{
        section.setAttribute('aria-hidden', 'false')
      })
    }
  }, [])

  function handleExitClick(e) {
    document.getElementById('transformImg' + props.sourceImgIndex).focus()
    props.dispatch({active: false})
  }

  const handleModalChooserClick = (e, direction) => {
    e.stopPropagation()
    let nextImgSourceIndex
    const relativeImgIndex = state.currentImgArray.findIndex(element => element === state.sourceImgIndex)

    if (direction === "left"){
      if (relativeImgIndex === 0) return
      nextImgSourceIndex = state.currentImgArray[relativeImgIndex - 1]
    } 
    else { //dir is right
      if (relativeImgIndex === state.currentImgArray.length - 1) return
      nextImgSourceIndex = state.currentImgArray[relativeImgIndex + 1]
    } 
    handleCommand({sourceImgIndex: nextImgSourceIndex})
  }

  function prepareDrag(e){
    e.stopPropagation()
    e.preventDefault()
  
    let specificEvent = e
    //Warning Chromedevtools gets screwed up the second you multitouch. From that point on it registers single touches as multi-touches too.
    if (e.type === "touchstart"){ 
      //Cancel every possible drag if user is multitouching
      if (e.touches.length > 1){
        document.removeEventListener('touchmove', handleDrag)
        document.removeEventListener('touchend', handleDrag)
        dragHandles.forEach(dragElement => { 
          dragElement.current.style.transform = null
          //re-add the slowing transitions when done dragging
          dragElement.current.classList.add('smoothTransition')
        })
        return
      } 
      specificEvent = e.touches[0]
      touchStartTime.current = new Date().getTime()
    }

    lastMousePos.current = specificEvent.clientX;
    originalMousePos.current = specificEvent.clientX;

    document.addEventListener('mousemove', handleDrag)
    document.addEventListener('touchmove', handleDrag)
    document.addEventListener('mouseup', finishDrag)
    document.addEventListener('touchend', finishDrag)

    //remove the slowing transitions during dragging
    dragHandles.forEach(dragElement => 
      dragElement.current.classList.remove('smoothTransition')
    )
  }

  function handleDrag(e){
    e.stopPropagation()
    let specificEvent = e
    if (e.type === "touchmove"){
      //Cancel every possible drag if user is multitouching
      if (e.touches.length > 1){
        document.removeEventListener('touchmove', handleDrag)
        document.removeEventListener('touchend', handleDrag)
        dragHandles.forEach(dragElement => { 
          dragElement.current.style.transform = null
          //re-add the slowing transitions when done dragging
          dragElement.current.classList.add('smoothTransition')
        })
        return
      } 
      specificEvent = e.touches[0]
    }

    // calculate the new mouse position:
    const change = lastMousePos.current - specificEvent.clientX;
    lastMousePos.current = specificEvent.clientX;
    // set the new position for each element (carousel img wrapper):
    dragHandles.forEach(dragElement => 
      dragElement.current.style.transform = 'translateX(' + (dragElement.current.getBoundingClientRect().left - change) + 'px)'
    )
  }

  function finishDrag(e){
    e.stopPropagation()
    console.log("mouseup/touchend called")
    let specificEvent = e
    if (e.type === "touchend") specificEvent = e.changedTouches[0]

    dragHandles.forEach(dragElement => { 
      dragElement.current.style.transform = null
      //re-add the slowing transitions when done dragging
      dragElement.current.classList.add('smoothTransition')
    })

    const totalChange = specificEvent.clientX - originalMousePos.current
    if (totalChange > 50) handleModalChooserClick(e, 'left')
    else if (totalChange < -50) handleModalChooserClick(e, 'right')
    else if (e.type === "touchend"){
      const endTime = new Date().getTime()
      const totalTime = endTime - touchStartTime.current
      if (totalTime <= 500 && Math.abs(totalChange) > 10) {
        handleModalChooserClick(e, totalChange > 10 ? 'left' : 'right')
      }
    }
    
    document.removeEventListener('mousemove', handleDrag)
    document.removeEventListener('touchmove', handleDrag)
    document.removeEventListener('mouseup', finishDrag)
    document.removeEventListener('touchend', finishDrag)
  }

  function getCarousel() {
    let chunkArrayIndex = state.currentImgArray.findIndex(element => {
      return element === state.sourceImgIndex
    })
    if (chunkArrayIndex === -1) {console.log("serious error, index not found for carousel"); return}

    let carouselArray = []
    //If first image, have no image to the left of it
    if (chunkArrayIndex===0){ 
      //Edge case of literally 1 single image
      if (state.currentImgArray.length === 1) carouselArray.push(
        {imgSourceIndex: -1, id:'leftCarouselImg'},
        {imgSourceIndex: state.currentImgArray[0], id:'centerCarouselImg'},
        {imgSourceIndex: -2, id:'rightCarouselImg'}
        )
      
      //Active img is first img in array
      else carouselArray.push(
        {imgSourceIndex: -1, id:'leftCarouselImg'},
        {imgSourceIndex: state.currentImgArray[0], id:'centerCarouselImg'}, 
        {imgSourceIndex: state.currentImgArray[1], id:'rightCarouselImg'}
      )
    } 
    //If last image, have no image to the right of it
    else if (chunkArrayIndex === (state.currentImgArray.length - 1)){
      carouselArray.push(
        {imgSourceIndex: state.currentImgArray[(chunkArrayIndex - 1)], id:'leftCarouselImg'}, 
        {imgSourceIndex: state.currentImgArray[chunkArrayIndex], id:'centerCarouselImg'},
        {imgSourceIndex: -2, id:'rightCarouselImg'}
      )
    }
    //Img somewhere in the middle
    else {
      carouselArray.push(
        {imgSourceIndex: state.currentImgArray[(chunkArrayIndex - 1)], id:'leftCarouselImg'}, 
        {imgSourceIndex: state.currentImgArray[chunkArrayIndex], id:'centerCarouselImg'},
        {imgSourceIndex: state.currentImgArray[chunkArrayIndex + 1], id:'rightCarouselImg'}
      )
    }

    let carousel = carouselArray.map( (carouselSection, index) => {
      return(
        <div 
          className="carouselImgWrapper smoothTransition" 
          key={"carouselImgWrapper" + carouselSection.imgSourceIndex} 
          id={carouselSection.id}
          ref={dragHandles[index]}
          onMouseDown={prepareDrag}
          onTouchStart={prepareDrag}
        >
          {(carouselSection.imgSourceIndex > -1) && 
          <Img 
            alt={carouselSection.id === 'centerCarouselImg' ? ('carousel image ' + (chunkArrayIndex + 1) + ' of ' + state.currentImgArray.length) : ''} 
            style={{ height: "100%", width: "70%", margin: "auto" }} 
            imgStyle={{objectFit: 'contain'}} 
            fluid={props.sourceImageArray[carouselSection.imgSourceIndex].childImageSharp.fluid}>
          </Img>}
        </div>
      )
    })

    return carousel
  }

  const chunkArrayIndex = state.currentImgArray.findIndex(element => {
    return element === state.sourceImgIndex
  })

  return(
    <section id={props.dataKey} css={getCSS()} role="dialog" className='LandmarkSection'>
      {getCarousel()}

      <div className="svg-wrapper right-button">
        <button 
          className={"modalChooserButton right-button" + (chunkArrayIndex === state.currentImgArray.length - 1 ? ' faded' : '')} 
          tabIndex={2} 
          aria-label='right image carousel' 
          onClick={(e)=> handleModalChooserClick(e, "right")}>
          <SVG__jhEvaIcons__Padless__arrow_ios_forward_outline />
        </button>
      </div>

      <div className="svg-wrapper left-button">
        <button 
          className={"modalChooserButton left-button" + (chunkArrayIndex === 0 ? ' faded' : '')}
          tabIndex={1} 
          aria-label='left image carousel' 
          onClick={(e)=> handleModalChooserClick(e, "left")}>
          <SVG__jhEvaIcons__Padless__arrow_ios_back_outline />
        </button>
      </div>

      <button id="exit-button" aria-label='exit modal carousel' tabIndex={3} onClick={handleExitClick}>
        <SVG__EvaIcons__close_outline />
      </button>
    </section>
  )
}

export default React.memo(ModalCarouselComponent, ()=>true)

//!
//*
//*CSS FROM THIS POINT BELOW
//*
//*CSS FROM THIS POINT BELOW
//*
//?

// export const ModalCarouselComponentEmotion = (sectionId) => css`
const getCSS = () => css`
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
  background-color: rgba(0,0,0,0.9);
  z-index: 99;
  touch-action: none; //?Very important for disabling scrolling when swiping in mobile
  touch-action: pinch-zoom; //?still allow zoom

  .carouselImgWrapper {
    position: absolute;
    width: 100vw;
    height: 100%; //?vh isn't reliable on phones b/c of their dropdown search/option bars
    text-align: center;
    left: 0;
  }

  .smoothTransition {
    transition: transform 0.3s;
  }

  #leftCarouselImg{
    transform: translateX(-100vw)
  }

  #centerCarouselImg{
  }

  #rightCarouselImg{
    transform: translateX(100vw)
  }

  .svg-wrapper{
    position: absolute;
    width: 10%;
    max-width: 125px;
    //?The following properties center vertically for absolute positioning of wrapper and content within
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .modalChooserButton{
    position: absolute;
    fill: white;
    width: 100%;
    min-width: 50px;
    max-width: 100px;
    min-height: 150px;
    padding: 10px;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    svg {
      width: 100%;
      max-height: 80px;
    }
  }

  .faded{
    fill: gray;
  }

  .left-button{
    left: 0;
  }

  .right-button{
    right: 0;
  }

  #exit-button{
    position: absolute;
    box-sizing: content-box;
    top: 0px;
    right: 0px;
    padding: 0;
    border: 0;
    width: 10%;
    min-width: 60px;
    max-width: 100px;
    fill: white;
    background-color: transparent;
    cursor: pointer;

    svg {
      width: 100%;
    }
  }

  @media (min-width: 600px) and (min-height: 400px){
    .smoothTransition {
      transition: transform 1s;
    }
  }
`