import React, { useEffect, useRef, useState } from "react"
import {css} from '@emotion/core'
import Img from "gatsby-image"

//? Uses filter: brightness to darken (IE incompatable, non-breaking). Images with object-fit contain don't resize properly in IE and just fully expand. Looks bad but non-breaking.

/**
 * A React component for displaying a slide show hero landing page
 * @param {String} dataKey - a copy of key used for further naming conventions
 * @param {Number} slideIndex - the index for the current graphql node to display. I actually set it to -1 for a 'fade in from black screen' initial effect.
 * @param {graphqlImgNodes[]} slides - array of graphql nodes for the slides
 * @param {String[]} slideQuotes - quotes to coincide with the slides
 * @param {Img} logoImg - an optional logo Img to display on the top. This is currently configured for a gatsby fluid img.
 */
function HeroComponent(props) 
{
  const [state, setState] = useState({
    slideIndex: -1,
    priorSlideIndex: -1 //for hiding on update
  })

  const tickTock = useRef(true)

  //element Ref's
  const slideQuoteHolder = useRef(null)
  const slideShowOn = useRef(true)
  let slideShowTimer

  // slide animation effect handler, useLayoutEffect needed to prevent visual updates occuring before animation reset in the slideQuoteHolder (looks bad otherwise)

  
  useEffect(() =>{
    //On very first intro, have black screen fade into picture
    if (state.slideIndex === -1){
      slideShowTimer = setTimeout( ()=>{ 
        setState( state => 
          state.slideIndex === (props.slides.length - 1) ? 
          {slideIndex: 0, priorSlideIndex: state.slideIndex} : 
          {slideIndex: state.slideIndex + 1, priorSlideIndex: state.slideIndex} 
        )
      }, 2000 )
    }
    else {
      //each render, add the animation to have the text scroll up  
      //!TESTING  
      // if (tickTock.current) slideQuoteHolder.current.classList.add('moveUp')
      // else slideQuoteHolder.current.classList.add('moveUp2')
      //only continue the timed slideshow if the user hasn't manually pressed a slide button yet
      if(slideShowOn.current) {
        slideShowTimer = setTimeout( ()=>{ 
          setState( state => 
            state.slideIndex === (props.slides.length - 1) ? 
            {slideIndex: 0, priorSlideIndex: state.slideIndex} : 
            {slideIndex: state.slideIndex + 1, priorSlideIndex: state.slideIndex} 
          )
        }, 5000 )
      }
    }

    //stop this timeout early if another render starts
    return (() => {

      //!TESTING
      // if (tickTock.current) slideQuoteHolder.current.classList.remove('moveUp')
      // else slideQuoteHolder.current.classList.remove('moveUp2')
      tickTock.current = !tickTock.current

      clearTimeout(slideShowTimer)
    })
  }, [state.slideIndex]
  )

  const handleSlideButtonPress = (buttonSlideIndex, e)=>{
    //only advance on unique button press. But on any button press, make sure the slideshow stops.

    //!TESTING  
    // slideQuoteHolder.current.classList.remove('moveUp')
    slideShowOn.current = false
    clearInterval(slideShowTimer)
    if(buttonSlideIndex !== state.slideIndex){
      setState(state => ({slideIndex: buttonSlideIndex, priorSlideIndex: state.slideIndex}))
    } 
  }

  function getSlides(){
    let slideArray = []

    for (let i=0; i < props.slides.length; i++){
      let animation = ''
      if (i === state.priorSlideIndex ){
        animation =  "animateHide"
      }
      else if (i === state.slideIndex ) {
        animation = "animateShow"
      }
      else animation="plainHide"

      let slide = <div className={"slideWrapper " + animation} key={"slideWrapper" + i}> 
        <Img  
        style={{height: '100%', width: '100%'}} 
        fluid={props.slides[i].childImageSharp.fluid}
        fadeIn={false}
        loading='eager'
        >
        </Img>
        <div style={{width:"100%", height:"100%", position:'absolute', left:'0', top:'0', backgroundColor:'rgba(0,0,0,0.25)'}}/>
    
      </div>

      slideArray.push(slide)
    }


    return slideArray
  }

  //Prepare the slide buttons, making sure to add the "focused" id to the current active/selected button
  let slideButtonArray = []
  const ariaLabels = ['commercial slide', 'residential slide', 'drone slide']
  for (let i=0; i<props.slides.length; i++)
  {
    let slideButton = <button className="slideButton" key={props.dataKey + "slideButton" + i} onClick={(e)=>handleSlideButtonPress(i,e)} aria-label={ariaLabels[i]}></button>
    if (i=== state.slideIndex) slideButton = React.cloneElement(slideButton,{id:"focusedSlideButton"})
    slideButtonArray.push(slideButton)
  }

  return(
    <section id={props.dataKey} css={getCSS()} role='banner' className='LandmarkSection'>
      {props.logoImg && 
        <h1 id='MainScreenTitle'>
          <Img alt='Monckton Photography' fadeIn={false} className="logoImg fromNoToShow" loading='eager' imgStyle={{ objectFit: "contain", objectPosition: "center"}} fluid={props.logoImg}></Img>
        </h1>
      }
      <div className="slideHolder" key="stableHolder">
        {getSlides()}
        <div className="slideQuoteHolder moveUp2" key={"quote" + state.slideIndex} ref={slideQuoteHolder}>
          {state.slideIndex !== -1 && props.slideQuotes[state.slideIndex]}
        </div>
        <div id="slideButtonHolder">
          {slideButtonArray}
        </div>
      </div>
    </section>
  )
}

export default React.memo(HeroComponent, ()=>true)

//!
//*
//*CSS FROM THIS POINT BELOW
//*
//*CSS FROM THIS POINT BELOW
//*
//?

//?I just use 2.8 instead of this
const logoWidthHeightRatio = 1073/381
const percent = '%'
const pixel = 'px'
// export const HeroComponentEmotion = (sectionId) => css`
const getCSS = () => css`
  height: 85vh; //?Gotta do it this way to compensate for url bar on mobile
  background-color: white;

  h1 {
    margin: 0;
  }

  .logoImg {
    position: absolute !important;
    left: 0;
    right: 0;
    margin: auto;
    top: 3.5em; /*Based off navHeight, changes in mediaquery */
    width: 100%;
    max-height: 35%;
    z-index: 1;
  }

  .slideHolder {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow: hidden; //?Needed for rising quote transition from below div
  }

  .slideWrapper {
    top: 0;
    position: absolute !important;
    height: 100%;
    width: 100%;
    /* will-change: opacity; */
  }

  .slideQuoteHolder{
    position: absolute;
    width: 100%;
    font-size: 2.5em;
    font-family: 'maven pro';
    text-align: center;
    text-shadow: 0px 0px 2px black;
    opacity: 0.5;
    //?px amount somewhat above the top of the slide buttons (which have 25px height along with being 15px from bottom in phone mode)
    bottom: 60px;
    color: white;
  }

  .plainShow {
    opacity: 1;
  }

  .plainHide {
    opacity: 0;
  }

  .animateHide {
    opacity: 0;
    transition: opacity 2s;
    /* animation: fadeOut 2s; */
  }

  .animateShow {
    opacity: 1;
    transition: opacity 2s;
    /* animation: fadeIn 2s; */
  }

  .fromNoToShow {
    animation: fadeIn 1.5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    50%{
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .moveUp {
    /* will-change: transform, opacity; */
    //? ease in cubic
    animation: slideUpReverse 1.5s cubic-bezier(0.32, 0, 0.67, 0) reverse;
    animation-fill-mode: forwards;
  }

  .moveUp2 {
    /* will-change: transform, opacity; */
    //? ease out cubic
    animation: slideUp 1.5s cubic-bezier(0.33, 1, 0.68, 1);
    animation-fill-mode: forwards;
  }

  //? For the quotes. They are positioned with bottom based off a px amount. This means their top y (which is for transform) is 100% plus that px amount above the bottom of the container. Set the transform to be a little more than that.
  @keyframes slideUpReverse {
    0% {
      transform: translate(0);
      opacity: 1;
    }
    100% {
      transform: translate(0, calc(100${percent} + 70${pixel}));
      opacity: 0;
    }
  }

  @keyframes slideUp {
    0% {
      transform: translate(0, calc(100${percent} + 70${pixel}));
      opacity: 0;
    }
    100% {
      transform: translate(0);
      opacity: 1;
    }
  }

  #slideButtonHolder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    min-width: 200px;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    bottom: 15px;
  }

  .slideButton {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 4px solid white;
    border-bottom: 4px double white;
    border-top: 4px double white;
    background-color: transparent;
    transition: transform 0.75s;
    padding: 0px;

    &:focus{
      outline: white solid thin;
      outline-offset: 3px;   
    }

    &:hover {
      cursor: pointer !important;
    }
  }

  #focusedSlideButton {
    transform: scale(1.25) rotate(90deg);
    transition: transform 0.75s;
  }

  @media (min-width: 600px) and (min-height: 400px){
    .logoImg{
      top: 4.25em;
      max-height: 25%;
    }

    .slideQuoteHolder {
      font-size: 3.5em;
      text-shadow: 0px 0px 5px black;
      bottom: 125px;
      opacity: 0;
    }

    .slideButton {
      height: 30px;
      width: 30px;
      border: 5px solid white;
      border-bottom: 5px double white;
      border-top: 5px double white;
    }

    #slideButtonHolder{
      bottom: 40px;
    }
    @keyframes slideUpReverse {
      0% {
        transform: translate(0)
      }
      100% {
        /* transform: translate(0, 250%); */
        transform: translate(0, calc(100${percent} + 125${pixel}));
      }
    }

    @keyframes slideUp {
      0% {
        /* transform: translate(0, 250%) */
        transform: translate(0, calc(100${percent} + 125${pixel}));
        opacity: 0;
      }
      100% {
        transform: translate(0);
        opacity: 1;
      }
    }
  }
`
