//Utility
import React, {useState, useReducer } from "react"
import { graphql } from "gatsby"
import {css, Global} from '@emotion/core'
import {Helmet} from "react-helmet"

import WorkSection from "../components/work-component"
import NavSection from "../components/nav-section"
import HeroSection from "../components/hero-section"
import ContactSection from "../components/contact-section"
import ServicesSection from "../components/services-section"
import ModalCarouselComponent from "../components/modal-carousel-component"
import FooterComponent from "../components/footer-component"
import ModalVirtualTourComponent from "../components/modal-virtual-tour"

//Fonts via typeface strategy
import "typeface-cormorant"
import "typeface-Maven-Pro"

//reference templates: https://www.templatemonster.com/demo/57651.html & 1oakstudios.com

const IndexPage = (props) => {
  //The data prop contains the results of the GraphQL query. You can use destructuring to explicitly start off with data instead of props. It would be ({data}) instead of (props)
  const data = props.data
  
  //*WorkSection preparation
  const [allNodes] = useState( ()=> data.landscape.nodes.concat(data.residential.nodes, data.commercial.nodes))

  const [imgCategoryInfo] = useState( ()=> {
    const imgCategoryInfo = []

    imgCategoryInfo.push({categoryName: 'all', startIndex: 0, chunkLength: allNodes.length}) 

    imgCategoryInfo.push({categoryName:'landscape', startIndex: 0, chunkLength: data.landscape.nodes.length})

    imgCategoryInfo.push({categoryName:'residential', startIndex: data.landscape.nodes.length, chunkLength: data.residential.nodes.length}) 

    imgCategoryInfo.push({categoryName:'commercial', startIndex: (data.landscape.nodes.length + data.residential.nodes.length), chunkLength: data.commercial.nodes.length}) 

    return imgCategoryInfo
  })

  //*Modal Section Preparation: Basic uncolumned arrays 
  //? in the future, this along with the modal section logic could be changed to work based off imgCategoryIndexBounds used above.
  let [baseSelections] = useState(()=>{
    const landscapeIndexes = [], residentialIndexes = [], commercialIndexes = [], allIndexes = []
    for (let i=0; i < allNodes.length; i++){
      if (i < data.landscape.nodes.length) landscapeIndexes.push(i)
      else if (i < (data.landscape.nodes.length + data.residential.nodes.length)) residentialIndexes.push(i) 
      else commercialIndexes.push(i)
  
      allIndexes.push(i)
    }
    return [allIndexes, landscapeIndexes, residentialIndexes, commercialIndexes]
  })

  //*HeroSection preparation
  const heroSlideQuotes = [
    "Your Business",
    "Your Home",
    "Your Perspective"
  ]

  //*NavSection preparation
  const menuTitles = ["Home", "Our Work", "Services", "Contact"]
  const menuJumpIDs = ["#HeroSection", "#WorkSection", "#ServicesSection", "#ContactSection"]

  //*CSS: first global, then compose all component CSS along with global into 1 CSS
  const globalCSS = css`
  * {
    font-family: "Cormorant";
    box-sizing: border-box;
  }

  html{
    overflow-y: scroll;
  }

  body {
    margin: 0px;
    background-color: white;  
  }

  a {
    text-decoration: none;
    /* outline: none; */
    &:hover{
      cursor: pointer;
    }
  }
  `
  const [navId, heroId, workId, servicesId, contactId, footerId, carouselId, virtualTourId] = ['NavSection', 'HeroSection', 'WorkSection', 'ServicesSection', 'ContactSection', 'FooterSection', 'ModalCarouselComponent', 'VirtualTourComponent']

  const [allCSS] = useState(()=> css`
    ${globalCSS};
  `)

  //*Reducers are solely for the sake of mounting and unmounting (in this case just the modals). Otherwise, state management always occurs within components.

  function carouselReducer(priorState, action) {
    const newState = {}
    //An image was clicked in work component and modal is meant to be mounted. Initialize with info given from work component.
    if (action.active){ 
      newState.active = true
      newState.sourceImgIndex = action.sourceImgIndex
      newState.currentImgArray = baseSelections[action.selectedArrayIndex]
    }
    //Close was clicked on modal and it's meant to be unmounted. Active of false will prevent INDEX return from ever executing component logic, so nothing has to be initialized
    else { 
      newState.active = false
    }
    return newState
  }
  const [modalMountState, modalCarouselDispatch] = useReducer(carouselReducer, {active: false})

  function virtualTourReducer(priorState, action) {
    const newState = {}
    if (action.active){
      newState.active = true
      newState.originFocusId = action.originFocusId
    }
    else {
      newState.active = false
    }
    return newState
  }
  const [virtualTourModalState, virtualTourModalDispatch] = useReducer(virtualTourReducer, {active: false})

  return(
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Monckton Photography | Ft. Lauderdale &amp; Miami</title>
      <meta name='description' content='Specializing in commercial, home, and landscape photography, with aerial drone and virtual tour capability. Serving all of Broward and North Dade.'/>
      <meta name='viewport' content="width=device-width, initial-scale=1"></meta>
      <html lang="en" />
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "url": "https://www.moncktonphotos.com",
            "name": "Monckton Photography",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "803 NE 4th Avenue",
              "addressLocality": "Fort Lauderdale",
              "addressRegion": "FL",
              "postalCode": "33304",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "26.133457",
              "longitude": "-80.140182"
            },
            "hasMap":"https://goo.gl/maps/DDfkpKGuPaNDnFGd9",
            "telephone": "954-282-1077",
            "description":"Residential and commercial photography with drone and virtual tour capability.",
            "logo" : "./logo.png",
            "image" : "./logo.png"
          }
        `}
      </script>
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-175176355-1"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-175176355-1');
        `}
      </script>
      <script>
        {`
          gtag('event', 'conversion', {'send_to': 'AW-610766689/gTpOCJGb1dgBEOGenqMC'}); 
        `}
      </script>
    </Helmet>

    <Global styles={allCSS}/>
    
    <NavSection key={navId} dataKey={navId} logoImg={data.logo.childImageSharp.fluid}  menuJumpIDs={menuJumpIDs} menuTitles={menuTitles} menuIndex={0} />

    <HeroSection key={heroId} dataKey={heroId} slideIndex={0} slides={data.slides.nodes} slideQuotes={heroSlideQuotes} logoImg={data.transparentLogo.childImageSharp.fluid}/>

    <WorkSection key={workId} dataKey={workId} 
    imgCategoryInfo={imgCategoryInfo} sourceImgArray={allNodes} selectedArrayIndex={0} 
    modalCarouselDispatch={modalCarouselDispatch} />

    <ServicesSection key={servicesId} dataKey={servicesId} background={data.background.childImageSharp.fluid} dispatch={virtualTourModalDispatch}/>

    <ContactSection key={contactId} dataKey={contactId}/>

    <FooterComponent key={footerId} dataKey={footerId} />

    {modalMountState.active && <ModalCarouselComponent key={carouselId} dataKey={carouselId} sourceImgIndex={modalMountState.sourceImgIndex} currentImgArray={modalMountState.currentImgArray} sourceImageArray={allNodes} dispatch={modalCarouselDispatch}/>}

    {virtualTourModalState.active && <ModalVirtualTourComponent key={virtualTourId} dataKey={virtualTourId} originFocusId={virtualTourModalState.originFocusId} dispatch={virtualTourModalDispatch}/>}

  </div>
  )
}

export default IndexPage

//-------------------------------------------------------------------------------//
//-------------------------------------------------------------------------------//
//-------------------------------------------------------------------------------//
//-------------------------------------------------------------------------------//

export const query = graphql`
  query {
    landscape: allFile(filter: {relativeDirectory: {eq: "landscape"}}, sort: {fields: childImageSharp___fluid___originalName}) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    commercial: allFile(filter: {relativeDirectory: {eq: "commercial"}}, sort: {fields: childImageSharp___fluid___originalName}) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    residential: allFile(filter: {relativeDirectory: {eq: "residential"}}, sort: {fields: childImageSharp___fluid___originalName}) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    slides: allFile(filter: {relativeDirectory: {eq: "slides"}}, sort: {fields: childImageSharp___fluid___originalName}) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_noBase64 
          }
        }
      }
    }

    logo: file(relativePath: {eq: "logo-black-400.png"}) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    transparentLogo: file(relativePath: {eq: "transWhiteLogo.png"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    background: file(relativePath: {eq: "background.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `