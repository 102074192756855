/*global commandRef*/

import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import {css} from '@emotion/core'
import Img from "gatsby-image"

//Decoration stuff
import UnderlineDecal from '../designs/doubleUnderline.js'

/**
 * A React component for displaying a group of images along with buttons to choose what group to show. Below are the destructured props.
 * 
 * @param {String} dataKey - a copy of key used for further naming conventions
 * @param {Object[] {categoryName: string, startIndex: int, chunkLength: int}} imgCategoryInfo - an array of objects which represent categories of images to display that come from a single sourceImgArray
 * @param {graphqlImgNodes[]} sourceImgArray - gatsby nodes for all images. The startIndexs and chunkLengths within imgCategoryInfo correspond to chunks of this array.
 * @param {Integer} selectedArrayIndex - the chosen category of images to initially show from the imgCategoryInfo array
 * @param {Function} modalCarouselDispatch - the dispatch to mount a modal carousel when an image in the gallery is selected
 * 
 */
function WorkComponent(props) 
{
  //Find initial column Count on first render
  const [state, setState] = useState(()=>{
    let initialColCount = 0
    //? can't use window in render for production build
    // if (window.innerWidth < 800) initialColCount = 2
    // else if (window.innerWidth < 1200) initialColCount = 3
    // else initialColCount = 4
    return({
    selectedArrayIndex: props.selectedArrayIndex,
    colCount: initialColCount,
    showGallery: false
    })
  })

  //allImgInfo is an array. The indexes correspond to the categories (chunks) and hold a column descriptive object. The keys of that object are 'columns4', 'columns3', etc. The values of those keys are the imgInfo prepared for that respective column count in that respective img category.
  const [allImgInfo] = useState(()=>{
    const allImgInfo = []

    function prepareChoiceArrays(maxColumns, minColumns, chunkLength, chunkStartIndex){
      let returnObject = {}
      for (let colCount = maxColumns; colCount> (minColumns - 1); colCount--){
        returnObject['columns' + colCount] = getImgInfo(colCount, chunkLength, chunkStartIndex, props.sourceImgArray, 0.5)
      }
      return returnObject
    }

    for (let i=0; i < props.imgCategoryInfo.length; i++){
      const imgCategoryObject = props.imgCategoryInfo[i]
      const preparedCategoryImgInfo = prepareChoiceArrays(4, 2, imgCategoryObject.chunkLength, imgCategoryObject.startIndex)
      allImgInfo.push(preparedCategoryImgInfo)
    }

    return allImgInfo
  })

  //? For possible future commands from a parent, pass a forwarded commandRef in the props. It would be initialized here. BEWARE the linter. I added the comment at the top of the js file to allow this short-circuit logic without throwing an error.
  // if (typeof commandRef !== "undefined" && commandRef.current === null) commandRef.current = handleCommand
     
  function handleCommand(commandedState) {
    let innerStateEntries = Object.entries(state)
    let commandedStateEntries = Object.entries(commandedState)
    let updatedValues = {}

    for (let [commandKey, commandValue] of commandedStateEntries) {
      let foundInnerStateEntry = innerStateEntries.find(innerStateEntry =>{
        return innerStateEntry[0] === commandKey
      })

      if (foundInnerStateEntry !== undefined){
        const innerStateValue = foundInnerStateEntry[1]
        if (innerStateValue === commandValue) console.log(props.dataKey + " command of '" + commandKey + "' has value: " + commandValue + " that already matched inner state")

        else updatedValues[commandKey] = commandValue
      }
      else {
        console.log(props.dataKey + " command of '" + commandKey + "' not recognized")
      }
    }

    if (Object.keys(updatedValues).length > 0){
      //? Why is the function form of this even needed if I can just use state?
      setState(prevState => {return{...prevState, ...updatedValues}})
    }  
  }

  function handleScroll(){
    //.The whole point is to load the gallery after the user starts scrolling in order to not load initially and get better Lighthouse score; so load it after roughly 2 inches of scrolling.
    if (Math.ceil(window.scrollY) >= 200) {
      handleCommand({showGallery: true})
      window.removeEventListener('scroll', handleScroll)
    }
  }

  //Done only once to initally find screen size to get colCount and load initial gallery
  useEffect(()=>{
    // let x = window.matchMedia("(min-width: 0px) and (max-width: 799px)")
    // let y = window.matchMedia("(min-width: 800px) and (max-width: 1199px)")
    // let z = window.matchMedia("(min-width: 1200px)")

    // //.Timeout for the sake of performance score boost
    // setTimeout(function(){
    //   if (x.matches) handleCommand({colCount:2})
    //   else if (y.matches) handleCommand({colCount:3})
    //   else handleCommand({colCount: 4})
    // }, 1500)

    let x = window.matchMedia("(min-width: 0px) and (max-width: 799px)")
    let y = window.matchMedia("(min-width: 800px) and (max-width: 1199px)")
    let z = window.matchMedia("(min-width: 1200px)")

    if (x.matches) handleCommand({colCount:2})
    else if (y.matches) handleCommand({colCount:3})
    else handleCommand({colCount: 4})

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  },[])

  //prepare media query for variant columns depending on screenwidth
  useEffect(()=>{
    const twoColumns = (match)=> {
      if (match.matches && state.colCount !== 0) { handleCommand({colCount:2}) } 
    }
    const threeColumns = (match)=> {
      if (match.matches && state.colCount !== 0) { handleCommand({colCount:3}) } 
    }
    const fourColumns = (match)=> {
      if (match.matches && state.colCount !== 0) { handleCommand({colCount:4}) } 
    }
    
    //These aren't like cascading styles. Have absolutes to make each media query exclusive
    let x = window.matchMedia("(min-width: 0px) and (max-width: 799px)")
    let y = window.matchMedia("(min-width: 800px) and (max-width: 1199px)")
    let z = window.matchMedia("(min-width: 1200px)")

    x.addListener(twoColumns) 
    y.addListener(threeColumns)
    z.addListener(fourColumns)

    return( ()=>{
      x.removeListener(twoColumns)
      y.removeListener(threeColumns)
      z.removeListener(fourColumns)
    }) //Gotta keep this fresh with colCount otherwise weird stuff happens
  },[state.colCount])

  function handleButtonClick(e, val) {
    e.stopPropagation();
    handleCommand({selectedArrayIndex: val})
  }

  function handleImageClick(sourceImageIndex){
    props.modalCarouselDispatch({
      active: true,
      sourceImgIndex: sourceImageIndex,
      selectedArrayIndex: state.selectedArrayIndex
    })
  }

  let imgInfo = null
  if (state.colCount !== 0) imgInfo = allImgInfo[state.selectedArrayIndex]['columns' + state.colCount]

  return(
  <section id={props.dataKey} css={getCSS()} aria-label='intro and image gallery' className='LandmarkSection'>
    <h2 className="section-title">ABOUT</h2>
    <UnderlineDecal targetID="workDecal"/>

    <p id="work-intro">Monckton Photography is an agency dedicated to providing personalized photography for your needs. I specialize in many areas of the residential and commercial districts, whether it be up close and personal or with beautiful panoramic drone photography. Even virtual tours can be prepared for your property.</p>

    <div id="button-row">
      {props.imgCategoryInfo.map( (categoryObject, index) =>{
        return(
          <button className="select-button" key={props.dataKey + "select-button" + index} aria-label={categoryObject.categoryName + ' images'} id={index === state.selectedArrayIndex ? "active-button" : ''} onClick={(e)=> handleButtonClick(e, index)}>{categoryObject.categoryName}</button>
        )
      })}
    </div>

    {/* The gallery must come from either vw or absolute value. vw does not take into account scrollbars. I must therefore center the gallery within another div that uses width: 100% to account for scrollbars. Be warned, gallery's dimensions are larger than intended if a scrollbar exists, so aim to make gallery slightly less than 100vw if going that route. Overflow hidden is also necessary to prevent x scrollbar. */}
    {imgInfo && <div style={{position: 'relative', height: (imgInfo.containingHeight + 'vw'), width:'100%', overflow: 'hidden'}}>
      <div id="gallery" style={{
        position: 'absolute',
        top: 0, bottom: 0, left: 0, right: 0,
        width: '90vw', 
        height: (imgInfo.containingHeight + 'vw'),
        margin: 'auto'
      }}
      >
        {state.showGallery && imgInfo.allImgInfo.map( (imgInfoObject, imgInfoIndex) => {
          return(
            <Flippy
              key={'flippyKey' + imgInfoObject.imgIndex /*order never changes*/} 
              altInfo={{
                imgCategory: props.imgCategoryInfo[state.selectedArrayIndex].categoryName, 
                index: imgInfoIndex + 1 - props.imgCategoryInfo[state.selectedArrayIndex].startIndex, 
                galleryLength: props.imgCategoryInfo[state.selectedArrayIndex].chunkLength}} 
              imgIndex={imgInfoObject.imgIndex} 
              isActive={imgInfoObject.isActive} 
              posX={imgInfoObject.x} 
              posY={imgInfoObject.y} 
              imgDivWidth={imgInfoObject.imgDivWidth} imgDivHeight={imgInfoObject.imgDivHeight} childImgSharp={props.sourceImgArray[imgInfoObject.imgIndex].childImageSharp.fluid} 
              clickHandler={handleImageClick} 
            />
          )
        })}  
      </div>
    </div>}
  </section>
  )
}

export default React.memo(WorkComponent, ()=>true)



/**
 * Flippy handles the complications of animating and fading in/out the images of the image gallery on updates. Each image is contained within a Flippy
 * ALL PROPS FOR POSITION AND DIMENSION ARE RELATIVE TO VIEWPORT WIDTH
 * @param {Number} imgIndex 
 * @param {Boolean} isActive 
 * @param {Number} posX 
 * @param {Number} posY
 * @param {Number} imgDivWidth 
 * @param {Number} imgDivHeight 
 * @param {gatsbyImgFluid} childImgSharp - the actual img 
 * 
 */
const Flippy = React.memo(props => {
  const cachedPosition = useRef(null)
  const priorActive = useRef(null)

  useEffect(()=>{
    const updatedElement = document.getElementById('transformImg' + props.imgIndex)
    if (!updatedElement) throw new Error('Error, couldnt find element with id: transformImg' + props.imgIndex)

    if (props.isActive) cachedPosition.current = {posX: props.posX, posY: props.posY}

    priorActive.current=props.isActive
  })

  //prepare the styling
  let styleObject = {
    position: 'absolute',
    width: props.imgDivWidth + 'vw',
    height: props.imgDivHeight + 'vw',
    opacity: 1,
    transition: null,
    pointerEvents: 'auto' 
  }

  //If it's entering, transition opacity
  if (props.isActive && !priorActive.current /*works for null or false*/){
    styleObject.transform =`translate(${props.posX}vw, ${props.posY}vw)`
    styleObject.transition = 'opacity 1s' 
  }
  //It's still active, transition translate and ignore cached position for transform
  else if (props.isActive && priorActive.current === true){
    styleObject.transform =`translate(${props.posX}vw, ${props.posY}vw)` 
    styleObject.transition = 'transform 1s'
  }
  //It's leaving now. Use cached position for transform. Transition opacity to 0. Make pointer events none (no clicking allowed of hidden elements)
  else if (!props.isActive && priorActive.current === true){
    styleObject.transform = `translate(${cachedPosition.current.posX}vw, ${cachedPosition.current.posY}vw)`
    styleObject.opacity = 0
    styleObject.transition = 'opacity 1s'
    styleObject.pointerEvents = 'none'
  }
  else {
    throw new Error('Error, this Flippy was not active and is still not active for imgIndex: ' + props.imgIndex)
  }

  //.The transform will be conditional based on priorActive
  return(
    <div
      role='img'
      id={'transformImg' + props.imgIndex}
      className='imgDivWrapper'
      onClick={()=> props.clickHandler(props.imgIndex)}
      style={styleObject}
      tabIndex={props.isActive === true ? 0 : -1}
      aria-label={props.isActive === true ? (props.altInfo.imgCategory + ' gallery image ' + props.altInfo.index + ' of ' + props.altInfo.galleryLength) : ''}
    >
      <Img style={{height: '100%'}} imgStyle={{objectFit: 'cover'}} fluid={props.childImgSharp}></Img>
    </div>
  )
},(oldProps, newProps)=>{
  //do not update if it was previously hidden and is still hidden
  if (!oldProps.isActive && !newProps.isActive) return true
  return false
})

/*
Preparing gallery of aspect ratio compliant images. The widths are the same on all images and their heights are variable to conform to the aspect ratio. The widths confine to equally sized columns. The chunkStartIndex and chunkLength define the desired images that are actually a chunk of a source array (that chunk may be the 'houses' index bounds of a source array containing houses, buildings, bridge images in it ).

What is returned is an object. The first key is containing Height which is the total height that the grandpa container should be (basically the height of the tallest column). The other key is allImgInfo. It's an array of imgInfoObjects. In each object are the representing the x, y, imgDivWidth, imgDivHeight of the div, along with the gridBoxHeight and Width (not really necessary). The x and y are the translate locations for the imgDiv.

.gap may have to be in the same units as the width. It's gonna complicate matters to have the stuff being returned for dimensions and locations to be an arithmetic expression of different units.

.ORDER OF CALCULATIONS
.gridBoxWidth (same as column width): vw/colCount
.imgDivWidth: gridBoxWidth - 2gap
.imgDivHeight: imgDivWidth/img Aspect Ratio 
.gridBoxHeight: imgDivHeight + 2gap
.x: ((colCount - 1) * gridBoxWidth) + gap
.y (tricky): sum of prior gridBoxHeights + gap

.returned:
.{
.  containingHeight: vwVal,
.  allImgInfo: 
.    [
.      { 
.        x: TranslateXVal, 
.        y: TranslateYVal, 
.        imgDivWidth: vwVal,
.        imgDivHeight: vwVal,
.        gridBoxWidth: vwVal,
.        gridBoxHeight: vwVal, 
.        imgIndex: int
.      }, 
.      ...more objects
.    ]
.}
*/
const getImgInfo = (totalColumns, chunkLength, chunkStartIndex, sourceArray, gap) =>
{  
  //I must factor in the gap now for aggregate and target heights. Since I need to know the exact height for grandpa, I have to factor in the column width too. This loop now prepares allImgInfo, except for the x and y which must be prepared in another loop through after the aggregateImgHeight is found.
  let aggregateImgBoxHeight = 0

  let allImgInfo = []

  let VWPERCENT = 90

  //prepare ALL the images. Attach one more property to show whether it's active or not
  for (let i=0; i< sourceArray.length; i++){
    let imgInfoObject = {}
    imgInfoObject.gridBoxWidth = VWPERCENT/totalColumns //same as column width. 100 respresents 100vw
    imgInfoObject.imgDivWidth = imgInfoObject.gridBoxWidth - 2*gap
    imgInfoObject.imgDivHeight = imgInfoObject.imgDivWidth/sourceArray[i].childImageSharp.fluid.aspectRatio
    imgInfoObject.gridBoxHeight = imgInfoObject.imgDivHeight + 2*gap
    imgInfoObject.imgIndex = i
    //initially treat translates as inactive
    imgInfoObject.x = 0
    imgInfoObject.y = 0

    if (i >= chunkStartIndex && i < (chunkStartIndex + chunkLength)){
      aggregateImgBoxHeight += imgInfoObject.gridBoxHeight
      imgInfoObject.isActive = true
    } 
    else imgInfoObject.isActive = false

    allImgInfo.push(imgInfoObject)
  }
  
  let targetColumnHeight = aggregateImgBoxHeight/totalColumns

  //holds the allImgInfo index to begin with on each new column (based off where the last column ended)
  let currentIndex = 0
  //this is a special compensator for the conditionals that decide whether to add an img (and cause slight overflow) or move on to another column (and cause slight gap). This compensator takes into account past overflows or empty gaps from prior columns.
  let leftOver = 0 
  const columnHeights = [] //all column heights are held until final comparison to find the tallest for granpda's height

  const returnObject = {}

  //Perpare each column (c represent column index)
  for (let c=0; c < totalColumns; c++)
  {
    let currentColumnHeight = 0
    //populate with images, j represents an imgInfoObject from allImgInfo
    for (let j = currentIndex; j < allImgInfo.length; j++)
    {
      const imgInfoObject = allImgInfo[j]
      if (!imgInfoObject.isActive) {

      }
      //Still room to add more images to current columns, or it's the last column and we have to add remaining images. Either way, the loop continues.
      else if ( (c === totalColumns - 1) || (currentColumnHeight + imgInfoObject.gridBoxHeight < targetColumnHeight) ){
        //x and y are the translated location of the imgDiv 
        imgInfoObject.x = (imgInfoObject.gridBoxWidth * c) + gap
        imgInfoObject.y = currentColumnHeight + gap
        currentColumnHeight += imgInfoObject.gridBoxHeight

        //On the very last active image, push the final column height for comparison
        if (j === (chunkStartIndex + chunkLength -1) ) columnHeights.push(currentColumnHeight)
      }
      //Img will go past target height for column, so decide what to do with img and break the loop to move on to next column. Update currentIndex so that this loop starts off on the next column where it left off on this column.
      else {
        //Remaining space is more than half the img height (leftOver compensated too), so add it and move on to next column. 
        if ( (targetColumnHeight - currentColumnHeight - leftOver) > imgInfoObject.gridBoxHeight/2){
          imgInfoObject.x = (imgInfoObject.gridBoxWidth * c) + gap
          imgInfoObject.y = currentColumnHeight + gap
          currentColumnHeight += imgInfoObject.gridBoxHeight

          //starting imgObject for next column will be the next imgObject
          currentIndex = j + 1
          // leftover increases by overflow amount, making the above conditional tougher  
          leftOver += (currentColumnHeight + imgInfoObject.gridBoxHeight) - targetColumnHeight  
        } 
        //Img will go over by more than half its height, move on to next column and add it to that one.
        else {
          //starting imgObject for next column will still be this imgObject
          currentIndex = j
          // leftOver decreases by empty gap amount, making the "if" conditional easier
          leftOver += currentColumnHeight - targetColumnHeight
        }

        //Column done, push this complete column height for the final comparison of highest
        columnHeights.push(currentColumnHeight)
        //?This column is done so BREAK out of the j loop and move on to next column in i loop
        break 
      }
    }
  }
  //We can now see which column ended up being the tallest
  returnObject.containingHeight = Math.max(...columnHeights)
  returnObject.allImgInfo = allImgInfo
  // returnObject.allImgInfo = allFlippys
  return returnObject
}

//!
//*
//*CSS FROM THIS POINT BELOW
//*
//*CSS FROM THIS POINT BELOW
//*
//?

//?This is no longer used, but the emotion technique is valuable so I keep it
// this is for using calc() in emotion. See whmountains post for example https://github.com/emotion-js/emotion/issues/597
// const vw = 'vw'
// function createSpanRowCSS(){
// const spanRows = []
// for (let i=1; i<20; i++){
//   const indexString = "" + i
//   spanRows.push(css`
//     .rowSpan${indexString}{
//       grid-row-end: span ${indexString}
//     }
//   `)
//   }
//   return spanRows
// }
// in the css this would have generated many rows: ${createSpanRowCSS()};

// export const WorkComponentEmotion = (sectionId) => css`
const getCSS = () => css`
  text-align: center;
  padding: 50px 0px;
  background-color: white;

  //?Imported Decoration Styling
  #workDecal {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 25px;
    //.Firefox is finicky with the color. The <hr> elements inherit this border color. Simply setting color works for chrome, but not firefox. This way however works
    border-color: orange;
  }

  //?Actual Work Component Styling
  .section-title {
    font-size: 1.5em;
    margin-bottom: 0px;
  }

  #work-intro {
    max-width: 600px;
    width: 100%;
    margin: auto;
    margin-bottom: 40px;
    font-size: 1.25em;
    padding: 0px 30px;
  }

  #gallery{
    //? relative position and hidden overflow are needed to truly hide inactive image-resolution. Otherwise scrollbar expands past bottom of pg to fit them, and they're still even clickable (at least in firefox). Unfortunately it makes some minor weird image resizes happen during movements. I don't know why.
    /* position: relative;
    overflow: hidden;
    margin:auto;
    margin-top: 10px;
    width: 100vw; */
  }

  //?This thing is so good it doesnt even need will-change. will-change actually might make it worse for some reason on firefox
  .imgDivWrapper {
    /* will-change: transform, opacity; */

    //? Huge mess with simply animating the images on :hover. images themselves don't receive :hover. Ended up having to use 2 separate animations, transitions didn't work. Probably b/c the gatsby img was screwing things up and Flippy inline styles were overwriting things.
    img{
      animation: unhoverShrink 0.3s;
      animation-fill-mode: forwards;
    }

    &:focus {
      outline: blue solid thick;
    }

    &:hover {
      cursor: pointer;
      img {
        animation: hoverEnlarge 0.3s;
        animation-fill-mode: forwards;
      }
    }
  }


  @keyframes hoverEnlarge {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.125);
    }
  }

  @keyframes unhoverShrink {
    from {
      transform: scale(1.125);
    }
    to {
      transform: scale(1);
    }
  }

  .select-button {
    background-color: white;
    border: 1px solid black;
    margin: 10px 20px;
    padding: 20px;
    text-transform: uppercase;
    font-weight: 700;
    transform: scale(1);
    transition-property: background-color, color, transform;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    letter-spacing: 2px;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: black solid medium;
    }
  }

  #active-button {
    background-color: black;
    color: white;
    transform: scale(1.1);
    transition-property: background-color, color, transform;
    transition-duration: 0.5s;
    transition-timing-function: ease;

    &:focus {
      outline: white solid thin;
      outline-offset: -5px;
    }
  }

  @media (min-width: 600px) {
    .section-title {
      font-size: 2em;
    }

    #work-intro {
      font-size: 1.5em;
    }

    .select-button {
      font-size: 1.15em;
    }
  }
`