import React from "react"
import {css} from '@emotion/core'
import {SVG__Ionic__call_outline} from "../Utility/svg-loader.js"
import {SVG__Ionic__mail_outline} from "../Utility/svg-loader.js"


/**
 * @param {String} dataKey - a copy of key used for further naming conventions
 */
export default React.memo(props => {

return(
  <footer id={props.dataKey} css={getCSS()} className='LandmarkSection'>
    <div className='sectionChunk'>
      <div className='subSectionChunk'>
        <SVG__Ionic__call_outline/>
        Call
      </div>

      <div className='subSectionChunk'>
        954-282-1077 
      </div>
    </div>

    <div className='sectionChunk'>
      <div className='subSectionChunk'>
        <SVG__Ionic__mail_outline/>
        Email
      </div>

      <div className='subSectionChunk'>
        JackieThePhotographer@gmail.com
      </div>
    </div>
  </footer>
)
}, ()=>true)

// export const FooterComponentEmotion = (sectionId) => css`
const getCSS = () => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.25em;
  padding: 0px 15px 25px 15px;
  background-color: white;

  .email-redirection{
    unicode-bidi: bidi-override;
    direction: rtl;
  }

  .sectionChunk {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
  }

  .subSectionChunk {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0px;
    word-break: break-word;

    svg {
      height: 1em;
      width: 1em;
      margin: 0px 10px;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
    .sectionChunk {
      width: 50%;
      margin-bottom: 0px;
    }
  }

  @media (min-width: 800px) {
    padding: 50px 15px 75px 15px;
  }
`