import React from "react"
import {css} from '@emotion/core'

/**
 * Style the the div element by passing a targetID to the props. The main concerns are the border-color and the width dimension of the div. The <hr> elements inherit the border color and their length is based on the div width.
 */
export default (props) =>
  <div id={props.targetID}>
    <hr aria-hidden={true}
      css={css`
        margin: 2px auto;
        margin-top: 0px;
        border: none;
        border-bottom: 1px solid;
        //.inherit is necessary for firefox
        border-color: inherit;
        width: 70%;
      `}
    />
    <hr aria-hidden={true}
      css={css`
        margin: 2px auto;
        margin-bottom: 0px;
        border: none;
        border-bottom: 1px solid;
        border-color: inherit;
        width: 100%;
      `}
    />
  </div>