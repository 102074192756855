import React, { useRef, useEffect, useState } from "react"
import {css} from '@emotion/core'
import ReCAPTCHA from "react-google-recaptcha"
import {SVG__EvaIcons__checkmark_outline, SVG__Ionic_location_outline} from "../Utility/svg-loader"
//Decoration stuff
import UnderlineDecal from '../designs/doubleUnderline.js'

//? Must use polyfill for fetch api for IE. It's imported in gatsby-browser.js
/**
 * @param {String} dataKey - a copy of key used for further naming conventions
 */
export default React.memo( props => {

  const [sendStatus, setSendStatus] = useState('ready')
  const [captchaLoaded, setCaptchaLoaded] = useState(false)

  const captchaChecked = useRef(false)
  const googleMapRef = useRef(null)
  const googleMap = useRef(null)
  const marker = useRef(null)
  const infoWindow = useRef(null)

  const myLocation = {
    lat: 26.133457,
    lng: -80.140182
  }

  useEffect(()=>{
    let timeout
    if (sendStatus==='success'){
      timeout = setTimeout(()=>{
        setSendStatus('ready')
      }, 2000)
    }
  },[sendStatus])

  function onChange(response){
    let hiddenCheckInput = document.getElementById('recaptcha-required')
    if (response === null) {
      hiddenCheckInput.value=''
      captchaChecked.current = false
    }
    else {
      hiddenCheckInput.value='good'
      captchaChecked.current = true
    }
  }

  function handleSend(event){
    event.preventDefault()
    //The hidden required field should always occur and never this alert. But this is here for any unforseen circumstance
    if (!captchaChecked.current){ 
      alert('please verify you are not a robot.')
      return
    }

    let url = 'https://q10vpuuoe8.execute-api.us-east-2.amazonaws.com/Alpha/sendmail'
    let name = document.getElementById('formName').value 
    let email = document.getElementById('formEmail').value
    let phone = document.getElementById('formPhone').value
    let message = document.getElementById('formMessage').value

    let bodyObject ={
      'name':name,
      'email':email,
      'phone':phone,
      'message':message
    }

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json')

    const options = {
      method: 'POST',
      headers: myHeaders,
      mode:'cors',
      body: JSON.stringify(bodyObject)
    }

    setSendStatus('sending')

    const handleResponse = (response) => {
      // if (response.ok){
      //   response.json().then(
      //     parsedResponse =>{
      //       console.log("yay response good: ")
      //       console.log(parsedResponse)
      //     }
      //   )
      // } 
      setSendStatus('success')
      // if (!response.ok) throw new Error('Network response was not ok' + response.status)
    }

    //? this uses a polyfill for EI that is imported in gatsby-browser.js for allowing production build to work
    fetch(url, options).then(handleResponse)
    .catch((error) => {
      console.error('There has been a problem with your fetch operation:', error);
    });
  }

  const createGoogleMap = () => 
    new window.google.maps.Map(googleMapRef.current, {
      zoom: 16,
      center: {
        lat: myLocation.lat,
        lng: myLocation.lng
      }
    })

  const createMarker = () =>
    new window.google.maps.Marker({
      position: {lat: myLocation.lat, lng: myLocation.lng},
      map: googleMap.current
    })

  let contentString = 
    '<div id="infoWindow">'+
      '<p id="infoTitle"><b>Monckton Photography</b></p>' +
      '<p id="infoAddress">803 NE 4th Avenue, Ft Lauderdale, FL 33304</p>'+
      '<a id="infoLink" target="_blank" href="https://www.google.com/maps/search/?api=1&query=803+NE+4th+Avenue%2C+Ft+Lauderdale%2C+FL%2C+33304">View in larger map</a>'+
    '</div>';

  function handleScroll(){
    const scrollSection = document.querySelector('#' + props.dataKey)
    if (Math.ceil(window.scrollY) >= (scrollSection.offsetTop - 200)) {
      const googleMapScript = document.createElement('script')
      googleMapScript.async = true
      googleMapScript.defer = true
      googleMapScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyA7CtSH3NcvLEwXlAUyDq_MzmnsBN02bw8&libraries=places"
      window.document.body.appendChild(googleMapScript)
      googleMapScript.addEventListener('load', () => {
        googleMap.current = createGoogleMap()
        marker.current = createMarker()
        infoWindow.current = new window.google.maps.InfoWindow({
          content: contentString
        })
        marker.current.addListener('click', function() {
          infoWindow.current.open(googleMap.current, marker.current);
        })
      })
      window.removeEventListener('scroll', handleScroll)
      if (!captchaLoaded) setCaptchaLoaded(true)
    }
  }

  //Scroll listener to only load map if user scrolls to the section
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  function removeFocus(e){
    e.target.blur()
  }

  function handleCaptchaLoad(e){
    if (!captchaLoaded) setCaptchaLoaded(true)
  }

  return(
  <section id={props.dataKey} css={getCSS()} aria-labelledby={'header' + props.dataKey} className='LandmarkSection'>
    <form id="contact-form" onSubmit={(e)=>handleSend(e)} onFocus={handleCaptchaLoad}> 
      <h2 className="section-title" id={'header' + props.dataKey}>Contact us</h2>
      <UnderlineDecal targetID='underlineDecal' />
      <div id="contact-info-container">
        <div className="contact-info-subcontainer">
          <input id="formName" aria-label='name' className="contact-info-text-input" type="text" placeholder="Your name" required/>
          <input id="formEmail" aria-label='email' className="contact-info-text-input" type="text" placeholder="Your email" required/>
          <input id="formPhone" aria-label='phone number' className="contact-info-text-input" type="text" placeholder="Your phone" required/>
        </div>
        <div className="contact-info-subcontainer">
          <textarea id="formMessage" aria-label='optional message' placeholder="Optional Message">
          </textarea>
        </div>
      </div>

      <div id="send-container">
        <button id="send-button" type="submit" disabled={sendStatus==='ready' ? false : true}>
          {sendStatus === 'ready' && 'SEND'}
          {sendStatus === 'sending' && <div id="loadingDiv"/>}
          {sendStatus === 'success' && <SVG__EvaIcons__checkmark_outline/>}
        </button>
        {captchaLoaded && <div id="recaptcha-holder">
          <input id="recaptcha-required" type='text' required tabIndex='-1' onFocus={removeFocus}/>
          <ReCAPTCHA sitekey="6LfWxO0UAAAAAPY2dTECmXFOxhvjg7Iq2cH3QQUL" onChange={onChange}/>
        </div>}
      </div>
    </form>  


    <div id="studioAddress">
      <div><SVG__Ionic_location_outline/>Studio Location</div>
      <div>803 NE 4th Avenue, Ft Lauderdale, FL 33304</div>
    </div>

    {/* comment out the map to remove when not in use */}
    <div id="map" 
    ref={googleMapRef} 
    />

    {sendStatus === 'success' && <div id="successToast" role='alert'>message sent</div>}
  </section>
  ) 
},
()=>true)

// export const ContactComponentEmotion = (sectionId) => css`
const getCSS = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 0px;
  background-color: white;

  #infoWindow{
    * {
      font-family: 'Times New Roman';
      font-size: 1em;
    }
    p{
      margin: 0px;
    }

  }
  #infoTitle {
    font-size: 1.1em;
  }

  #recaptcha-holder {
    position: relative;
  }

  #recaptcha-required {
    position: absolute;
    pointer-events: none;
    opacity: 0;
    top: 50%;
    left: 6%;
  }

  #map {
    background-color: lightgray;
    /* height: 400px; */
    width: 30vw;
    height: 30vw;
    min-width: 310px;
    min-height: 310px;
    margin-top: 20px;
  }

  #contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: none;
    margin-bottom: 40px;
  }

  .section-title {
    font-size: 2em;
    margin-bottom: 0px;
  }

  #underlineDecal {
    width: 200px;
    margin: 0 auto;
    margin-bottom: 25px;
    border-color: orange;
  }

  #contact-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }

  .contact-info-subcontainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 200px; //?uneeded b/c of text-area min-height, but good fallback
    padding: 20px;

    textarea {
      height: 100%;
      min-height: 200px; //? This kinda dictates the entire flex height in row and column form
      border: 1px solid gray;
      padding: 20px;
      font-size: 1.25em;

      &:focus{
        outline: none;
      }
    }
  }

  .contact-info-text-input {
    padding: 20px;
    border: 0px;
    border-bottom: 1px solid gray;
    font-size: 1.25em;
    flex: 1; //?allows input elements to grow, just overall good for even space distribution

    &:focus{
      outline: none;
    }
  }

  #send-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  //.This loading Div is hard-coded to fit within the send button
  #loadingDiv {
    position: absolute;
    height: 1.4em;
    width: 1.4em;
    margin: auto;
    top: 0; bottom: 0; left: 0; right: 0;
    border: 2px solid black;
    border-top: 2px solid white;
    border-right: 2px solid white;
    border-radius: 50%;
    animation: loading 1s linear infinite;
  }

  #studioAddress {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 15px;
    font-size: 1.25rem;
    text-align: center;
    justify-content: center;
    align-items: center;

    div{
      margin: 10px 0px;
    }

    svg {
      height: 1.25rem;
      width: 1.25rem;
      margin: 0;
    }
  }

  @keyframes loading {
    0%{
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(365deg)
    }
  }

  #send-button {
    position: relative;
    background-color: white;
    border: 1px solid gray;
    border: none;
    background-color: black;
    color: white;
    font-family: "maven pro";
    font-size: 1.25em;
    width: 4em;
    max-width: 4em;
    height: 2.1em;
    max-height: 2.1em;
    font-weight: 600;
    margin-bottom: 20px;
    padding: 0px;

    &:hover{
      cursor: pointer;
    }

    svg {
      fill: greenyellow;
      height: 100%;
    }
  }

  #successToast{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "maven pro";
    font-size: 1.5em;
    text-align: center;
    width: 5em;
    padding: 5px 0px;
    background-color: black;
    color: white;
    z-index: 100;
    animation: fadeInAndOut linear 2s;
    animation-fill-mode: forwards;
  }

  @keyframes fadeInAndOut {
    0%{
      opacity:0
    }
    25%{
      opacity:1
    }
    75%{
      opacity:1
    }
    100%{
      opacity:0
    }
  }

  @media (min-width: 750px) {
    #contact-info-container {
      flex-direction: row;
      max-width: 1300px;
    }
  }
`