import React, { useEffect, useState } from "react"
import {css} from '@emotion/core'
import {SVG__EvaIcons__close_outline} from "../Utility/svg-loader.js"
import CircleLoader from "../designs/circleLoader.js"

/**
 * @param {String} dataKey - a copy of key used for further naming conventions
 * @param {Function} dispatch - dispatch to Index for the unmounting of the modal virtual tour
 * @param {String} originFocusId - the id of the button to return focus to once the modal exits
 */
export default React.memo( props => {

  const [loadState, setLoadState] = useState(false)

  //? make all other sections aria hidden when modal is active, and restore when inactive
  useEffect(()=>{

    const loadFunction = function(){
      setLoadState(true)
    }
    document.getElementById('virtual-tour-iframe').onload = loadFunction

    const allSections = [...document.querySelectorAll('.LandmarkSection')]
    allSections.forEach(section => {
      if (section.id !== 'VirtualTourModal'){
        section.setAttribute('aria-hidden', 'true')
      }
    })

    function tabHandler(e) {
      let KEY_TAB = 9
      let KEY_ESC = 27
      const firstFocusable = document.querySelector('#virtual-exit-button')
      const lastFocusable = document.querySelector('#virtual-exit-button')
      if(e.keyCode === KEY_TAB){
        if (e.shiftKey){
          if (document.activeElement === firstFocusable){
            e.preventDefault()
            lastFocusable.focus()
          }
        }
        else if (document.activeElement === lastFocusable) {
          e.preventDefault()
          firstFocusable.focus()
        }
      }
      else if (e.keyCode === KEY_ESC) {
        handleExitClick(null)
      }
    }

    document.querySelector('#virtual-exit-button').focus()
    document.addEventListener('keydown', tabHandler)
    
    return ()=> {
      document.removeEventListener('keydown', tabHandler) 
      allSections.forEach(section =>{
        section.setAttribute('aria-hidden', 'false')
      })
    }
  }, [])

  function handleExitClick(e) {
    document.getElementById(props.originFocusId).focus()
    props.dispatch({active: false})
  }

  return(
    <section id={props.dataKey} css={getCSS()} role="dialog" className='LandmarkSection'>
      <div id='exit-button-section'>
        <button id='virtual-exit-button' onClick={handleExitClick}>
          <SVG__EvaIcons__close_outline/>
        </button>
      </div>

      {!loadState && <CircleLoader dataKey={props.dataKey + 'loader'}/>}

      <div id='iframe-section' aria-label='virtual-tour'>
        <div id='aspect-ratio-width'>
          <div id='aspect-ratio-height'>
            <iframe id='virtual-tour-iframe' title='Dominos Virtual Tour Sample' aria-hidden='true' width='853' height='480' src='https://my.matterport.com/show/?m=9j7km1ahFUj' frameBorder='0' 
            //sandbox placed in to prevent iframe from redirecting top window to new website
            sandbox='allow-scripts allow-same-origin'
            allow='xr-spatial-tracking' />
          </div>
        </div>
      </div>
    </section>
  )
},
()=>true)


const vh = 'vh'
// export const ModalVirtualTourComponentEmotion = (sectionId) => css`
const getCSS = () => css`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
  background-color: rgba(0,0,0,0.9);
  z-index: 99;
  touch-action: none; //?Very important for disabling scrolling when swiping in mobile

  #notLoaded {
    width: 100px;
    height: 100px;
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: blue;
  }

  #virtual-exit-button {
    padding: 0;
    border: 0;
    fill: white;
    background-color: transparent;
    cursor: pointer;

    svg {
      width: 100%;
    }
  }

  #iframe-section{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: green; */
  }

  #exit-button-section{
    /* background-color: red; */
  }

  //? 853x480 were the dimensions the iframe defaulted to from Jackie.
  #aspect-ratio-width { 
    width: 100%;
    //? this clever max-width ensures no height overflow
    max-width: calc(100${vh} * (853/480));
  }

  #aspect-ratio-height {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56%;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (orientation: portrait) {
    flex-direction: column;

    #exit-button-section{ //?width is fullscreen, have button width define itself
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    #virtual-exit-button {
      width: 10%;
      min-width: 60px;
      max-width: 100px;
    }
  }

  @media (orientation: landscape) {
    flex-direction: row-reverse;

    #exit-button-section{ //?width is literally the button width, just set button to 100%
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 10%;
      min-width: 60px;
      max-width: 100px;
    }

    #virtual-exit-button {
      width: 100%;
    }
  }
`